<template>
  <div
    class="notation-card"
    :class="{ 'notation-card--is-not-in-dashboard': !isInDashboard }"
  >
    <div class="notation-card-header">
      <MiniTag
        class="notation-card-state"
        :class="{
          'notation-card-state--with-progress':
            progression &&
            !['RESERVE', 'ENPREPARATION', 'REALISE'].includes(
              reservation.etat.uid
            ),
        }"
        :text="reservation.etat.designation"
        :color="colorMinitagState"
      />
      <MiniTag
        class="notation-card-progress notation-card-progress--with-state"
        v-if="
          progression &&
          !['RESERVE', 'ENPREPARATION', 'REALISE'].includes(
            reservation.etat.uid
          )
        "
        :text="`${progression}%`"
        :color="colorMinitagProgress"
      />
    </div>

    <div class="notation-card-body">
      <h3 class="notation-card-title">
         <router-link v-if="isInDashboard" :to="{
          name: 'essaiExperimentalTaskNotationStateEdit',
          params : {id:reservation.essai.id , tid:reservation.evenement.id} }">
         {{ reservation?.evenement?.designation }}
         </router-link>
         <template v-else >
            {{ reservation?.evenement?.designation }}
         </template>
        </h3>
      <div class="notation-card-notation">
        <span>Essai&nbsp;:</span> {{ reservation.essai?.nom }}
      </div>

      <template v-if="reservation.etat.uid !== 'ENPREPARATION'">
        <div class="notation-card-operator">
          <span>Technicien&nbsp;:</span>
          {{ reservation?.essai?.technicien?.nom_complet }}
        </div>
        <div class="notation-card-operator">
          <span>Opérateur&nbsp;:</span>
          {{ reservation.utilisateur.nom_complet }}
        </div>
        <div class="notation-card-date">
          <span>Date de réservation&nbsp;:</span> {{ date }}
        </div>
      </template>

      <template v-if="reservation.etat.uid === 'ENPREPARATION'">
        <div class="notation-card-operator">
          <span>Technicien&nbsp;:</span>
          {{ reservation?.essai?.technicien?.nom_complet }}
        </div>
        <div class="notation-card-date">
          <span v-if="reservation.evenement?.stade?.designation">Stade prévisionnel&nbsp;:</span>
          <span v-else >Date prévisionnelle&nbsp;:</span>
          {{ date_previsionnelle }}
        </div>
      </template>
    </div>

    <div class="notation-card-footer" >
      <Btn
        text="Réserver"
        color="primary"
        v-if="reservation.etat.uid === 'ENPREPARATION'"
        @click="notationService.bookNotation(reservation)"
      />
      <Btn
        text="Commencer"
        color="primary"
        v-if="isOwner && reservation.etat.uid === 'RESERVE'"
        @click="notationService.startNotation(reservation.id)"
      />
      <Btn
        text="Continuer"
        color="primary"
        v-if="isOwner && reservation.etat.uid === 'ENCOURS'"
        @click="notationService.continueNotation(reservation.id)"
      />
      <Btn
        text="Synchroniser"
        color="primary"
        v-if="isOwner && reservation.etat.uid === 'ATTENTESYNCHRO'"
        @click="notationService.synchronizeNotation(reservation.id)"
      />
      <Btn
        text="Réessayer"
        color="primary"
        v-if="isOwner && reservation.etat.uid === 'ERREURSYNCHRO'"
        @click="notationService.synchronizeNotation(reservation.id)"
      />

      <Btn
        text="Terminer"
        color="primary"
        v-if="isOwner && reservation.etat.uid === 'PARTIELLEMENTREALISE'"
        @click="endReservationModal = true"
      />

      <Btn
        text="Réserver pour continuer"
        color="primary"
        hollow
        v-if="reservation.etat.uid === 'PARTIELLEMENTREALISE'"
        @click="notationService.bookNotation(reservation)"
      />
      <Btn
        text="Réserver pour modifier"
        color="primary"
        hollow
        v-if="reservation.etat.uid === 'REALISE'"
        @click="notationService.bookNotation(reservation)"
      />

      <Btn
        text="Annuler la réservation"
        color="primary"
        hollow
        @click="cancelReservationModal = true"
        v-if="
        isOwner && (
          reservation.etat.uid === 'RESERVE' ||
          reservation.etat.uid === 'ENCOURS' ||
          reservation.etat.uid === 'ATTENTESYNCHRO' ||
          reservation.etat.uid === 'ERREURSYNCHRO'
        )
        "
      />
    </div>
  </div>
  <Modal
    title="Annuler la réservation"
    :active="cancelReservationModal"
    :data="reservation"
    @modal-close="cancelReservationModal = false"
  >
    <template v-slot:modal-body>
      <p><strong>Voulez vous vraiment annuler la réservation ?</strong></p>
      <p>Les données non synchronisées seront perdues.</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="cancelReservationModal = false" />
      <Btn
        text="Confirmer"
        @click="cancelReservation(data)"
        color="primary"
      />
    </template>
  </Modal>
   <Modal
    title="Terminer la réservation"
    :active="endReservationModal"
    :data="reservation"
    @modal-close="endReservationModal = false"
  >
    <template v-slot:modal-body>
      <p>Voulez vous vraiment terminer la réservation ?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="endReservationModal = false" />
      <Btn
        text="Confirmer"
        @click="endReservation(data)"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'NotationCard',

  components: {
    Btn,
    MiniTag,
    Modal,
  },

  props: {
    reservation: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    isInDashboard: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    progression() {
      return this.reservation.progression
        ? this.reservation.progression : this.reservation.notation.progression
    },
    date() {
      return this.helperService.displayDate(this.reservation.date)
    },
    date_previsionnelle() {
      return this.reservation.evenement?.stade?.designation
        ? this.reservation.evenement.stade.designation
        : this.helperService.displayDate(
          this.reservation.evenement.date_previsionnelle,
        )
    },
    colorMinitagState() {
      if (this.reservation.etat.uid === 'ENCOURS') {
        return 'notation-in-progress'
      }
      if (this.reservation.etat.uid === 'ATTENTESYNCHRO') {
        return 'notation-wait-synchro'
      }
      if (this.reservation.etat.uid === 'ERREURSYNCHRO') {
        return 'notation-error-synchro'
      }
      return 'primary'
    },
    colorMinitagProgress() {
      if (this.reservation.etat.uid === 'ENCOURS') {
        return 'notation-in-progress-light'
      }
      if (this.reservation.etat.uid === 'ATTENTESYNCHRO') {
        return 'notation-wait-synchro-light'
      }
      if (this.reservation.etat.uid === 'ERREURSYNCHRO') {
        return 'notation-error-synchro-light'
      }
      return 'primary-lighty'
    },
  },

  data() {
    return {
      cancelReservationModal: false,
      endReservationModal: false,
    }
  },
  methods: {
    cancelReservation(data) {
      this.notationService.cancelReservation(data)
      this.cancelReservationModal = false
    },
    endReservation(reservation) {
      this.notationService.endNotation(reservation, true)
      this.endReservationModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.notation-card {
}

.notation-card-header {
  margin-bottom: $gutter-quarter;
}

.notation-card-body {
  margin-bottom: $gutter-half;
}

.notation-card-footer {
  display: flex;
  flex-direction: column;
  gap: $gutter-quarter;

  .notation-card--is-not-in-dashboard & {
    @include bp("xs") {
      flex-direction: row;
    }
  }
}

.notation-card-title {
  @include h(2);
  color: $color-gray-darky;
  margin-bottom: $gutter-quarter;
}

.notation-card-state {
}

.notation-card-state--with-progress {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.notation-card-progress {
  border-left: 1px solid white;
  padding-left: ($gutter-eighth / 2 * 3);
  // Hide dot
  &::before {
    display: none !important;
  }

  &.notation-card-progress--with-state {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.notation-card-notation,
.notation-card-operator,
.notation-card-date {
  > span {
    color: $color-gray;
  }
}
</style>
